import { JigMetadata } from '@/store/modules/jig/types'
import { segmentEventTracking } from '@/utils/tracking'

export class JigTeamShareTracking {
  public static segmentEventTrackForJigTeamSharingSet(
    teamShareViewEnabled: boolean,
    teamShareViewDisabled: boolean,
    teamShareReshareEnabled: boolean,
    teamShareReshareDisabled: boolean,
    teamShareEditEnabled: boolean,
    teamShareEditDisabled: boolean,
    eventTracking: any,
    jigMetadata: JigMetadata
  ) {
    if (teamShareViewEnabled) {
      segmentEventTracking(
        'TeamSharingSet',
        {
          ...eventTracking,
          state: true,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    } else if (teamShareViewDisabled) {
      segmentEventTracking(
        'TeamSharingSet',
        {
          ...eventTracking,
          state: false,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    }

    if (teamShareReshareEnabled) {
      // If team resharing is enabled, send resharing tracking too.
      segmentEventTracking(
        'ReSharingSet',
        {
          ...eventTracking,
          state: true,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    } else if (teamShareReshareDisabled) {
      segmentEventTracking(
        'ReSharingSet',
        {
          ...eventTracking,
          state: false,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    }

    if (teamShareEditEnabled) {
      // If team can edit is enabled, send team-edit tracking too.
      segmentEventTracking(
        'TeamEditSet',
        {
          ...eventTracking,
          state: true,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    } else if (teamShareEditDisabled) {
      segmentEventTracking(
        'TeamEditSet',
        {
          ...eventTracking,
          state: false,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    }
  }
}
