
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import PrivateShareWarning from '@/components/share/PrivateShareWarning.vue'
import { JigConst } from '@/constants'
import { Component, Vue } from 'vue-property-decorator'

/**
 * This Vue component is a prompt popup modal that contains appropriate Jig related warning content.
 * This modal should be called accordingly whenever:
 * - 1. update Jig visbility
 * - 2. when failed to assign 1:1 Jig view permission
 *
 * Modal content type:
 * - 1. When user tried to update Jig visibility to `private`. Free tier and paying tier tenant users are handled differently.
 * - 2. When user tries to set Jig visibility to `link-password`. Non-org tier and org tier tenant users are handled differently.
 * - 3. When user tries to regenerate password
 * - 4. When copy a deeplink of `private` visibility Jig
 * - 5. When failed to assign user 1:1 Jig view permission
 *
 */
@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'private-share-warning': PrivateShareWarning,
  },
})
export default class ShareWarningPrompt extends Vue {
  private warningPromptKey: string = JigConst.JigVisibilities.Link
  private warningPromptContent: any = {
    [JigConst.JigVisibilities.Link]: {
      title: 'Just a reminder',
      copy: 'Anyone with the link will be able to view this Jig. If privacy is a concern consider making it <strong>private</strong> and giving access to specific people.',
      props: {
        requireCtas: true,
        yesBtnMsg: 'Share',
      },
      events: {
        'on-answer-yes': this.onWarningPromptShare,
      },
    },
    [JigConst.JigVisibilities.Private]: {
      title: 'Just a reminder',
      copy: 'This Jig is private, only authorized people will be able to view it.',
      props: {
        requireCtas: true,
        yesBtnMsg: 'Share',
      },
      events: {
        'on-answer-yes': this.onWarningPromptShare,
      },
    },
    [JigConst.JigVisibilities.Password]: {
      title: 'Just a reminder',
      copy: 'This Jig is private, people outside your team will need the password to view it.',
      props: {
        requireCtas: true,
        yesBtnMsg: 'Share',
      },
      events: {
        'on-answer-yes': this.onWarningPromptShare,
      },
    },
    privateWarning: {
      title: 'Upgrade to share your content privately',
      component: {
        name: 'private-share-warning',
        props: {
          eventName: 'SettingsPrivateSharing_UpgradeButtonClicked',
          callout: 'Secure, private, and invite-only Jigs on our Starter plan.',
        },
      },
      props: {
        requireCtas: false,
      },
      events: {},
    },
    passwordWarning: {
      title: 'Upgrade to share your content with a password',
      component: {
        name: 'private-share-warning',
        props: {
          eventName: 'SettingsPasswordSharing_UpgradeButtonClicked',
          callout: 'Share your content with clients or partners using password protection on our Organization plan.',
          cta: 'See pricing',
        },
      },
      props: {
        requireCtas: false,
      },
      events: {},
    },
    failedInvites: {
      title: 'Invitation failed',
      copy: '',
      props: {
        requireCtas: false,
      },
      events: {
        'on-answer-no': this.onWarningPromptClosed,
      },
    },
    passwordRegenerate: {
      icon: 'Icon_Info_filled',
      title: `Generate new password?`,
      copy: `Jig password already exists. Would like to generate a new one?`,
      props: {
        requireCtas: true,
        noBtnMsg: 'Keep existing',
        yesBtnMsg: 'New password',
      },
      events: {},
    },
  }

  public $refs!: Vue['$refs'] & {
    shareWarningPrompt: PromptYesNo
  }

  public ShowPrompt() {
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  public ClosePrompt() {
    this.$refs.shareWarningPrompt.ClosePrompt()
  }

  public SetWarningPromptKey(key: string) {
    this.warningPromptKey = key
  }

  public SetWarningPromptCopy(copy: string) {
    this.warningPromptContent[this.warningPromptKey].copy = copy
  }

  public SetPasswordRegeneratedWarningData(copy: string, events: any) {
    this.SetWarningPromptCopy(copy)

    for (const key in events) {
      this.warningPromptContent[this.warningPromptKey].events[key] = events[key]
    }
  }

  private warningPromptProps() {
    const props = { ...this.warningPromptContent[this.warningPromptKey].props }
    return props
  }

  private warningPromptEvents() {
    const events = { ...this.warningPromptContent[this.warningPromptKey].events }
    return events
  }

  private async onWarningPromptShare() {
    this.$emit('on-warning-prompt-share')
  }

  private onWarningPromptClosed() {
    this.$emit('on-warning-prommpt-closed')
  }
}
