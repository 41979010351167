
import HintTooltip from '@/components/helper/HintTooltip.vue'
import { JigConst, SubscriptionConst } from '@/constants'
import JigSharelink from '@/mixin/JigSharelink'
import { Tenant } from '@/store/modules/app/types'
import { JigMetadata, SharedJigItem, TenantJigPermissions } from '@/store/modules/jig/types'
import { Namespace, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { mixins } from 'vue-class-component'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: {
    'hint-tooltip': HintTooltip,
  },
})
export default class ShareableLinkVue extends mixins(JigSharelink) {
  @Prop({ type: Object, default: () => ({}) })
  public jig!: JigMetadata
  @Prop({ type: String, default: JigConst.JigVisibilities.Link })
  public jigVisibility!: JigConst.JigVisibilities
  @Prop({ type: String, default: '' })
  public itemDeeplink!: JigConst.JigVisibilities
  @Prop({ type: Boolean, default: false })
  public deeplinkURLRequesting!: boolean
  @Prop({ type: Boolean, default: false })
  public showButtonText!: boolean
  @Prop({ type: Boolean, default: false })
  public shareDisabled!: boolean
  @Prop({ type: Boolean, default: true })
  public readonlyJig!: boolean
  @Prop({ type: Number, default: TenantHelpers.InvalidTenantID })
  public myTenantID!: number
  @Prop({ type: Boolean, default: false })
  public isSuperUserOrJigStaff!: boolean

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('saveJigVisibilityResult', { namespace: Namespace.Jig })
  public saveJigVisibilityResult!: any

  public $refs!: Vue['$refs'] & {
    hintTooltip: HintTooltip
  }

  private isRequestingQRCode: boolean = false
  // `public` visibility behaviour falls back to `link`
  private activeVisibility: JigConst.JigVisibilities =
    this.jigVisibility !== JigConst.JigVisibilities.Public ? this.jigVisibility : JigConst.JigVisibilities.Link
  private configList: {
    [K in JigConst.JigVisibilities]?: {
      icon: string
      text: string
    }
  } = {
    [JigConst.JigVisibilities.Link]: {
      icon: 'Icon_Globe.svg',
      text: JigConst.jigVisibilities[JigConst.JigVisibilities.Link],
    },
    [JigConst.JigVisibilities.Private]: {
      icon: 'Icon_Shield.svg',
      text: JigConst.jigVisibilities[JigConst.JigVisibilities.Private],
    },
    [JigConst.JigVisibilities.Password]: {
      icon: 'Icon_Lock_light.svg',
      text: JigConst.jigVisibilities[JigConst.JigVisibilities.Password],
    },
  }
  private copyConfigList: {
    [K in JigConst.JigVisibilities]?: {
      icon: string
      text: string
    }
  } = {
    [JigConst.JigVisibilities.Link]: {
      icon: 'Icon_Link.svg',
      text: 'Copy link only',
    },
    [JigConst.JigVisibilities.Password]: {
      icon: 'Icon_Mail.svg',
      text: 'Copy link and password',
    },
  }
  private isConfigSelectActive: boolean = false
  private isCopyConfigSelectActive: boolean = false

  @Watch('jigVisibility', { immediate: true })
  onJigVisibilityChanged(val: JigConst.JigVisibilities) {
    this.setJigVisibility(val)
  }

  protected mounted() {
    this.$on('on-sharelink-tracking', (payload: any) => {
      this.shareableLinkSegmentTracking(payload)
    })
  }

  private get isJigEditable(): boolean {
    const jig = this.jigMetadata

    if (jig == null || !jig.RequestUserPermission || jig.RequestUserPermission.CRUD == null) {
      return false
    }

    return !this.readonlyJig
  }

  private get isOwner(): boolean {
    return this.jigMetadata.Author === this.$auth0.user.email
  }

  private get isSharedWithTeam(): boolean {
    // if the RequestUserPermission.ID == -1 or 0, and the RequestUserPermission.CRUD is null or RequestUserPermission.CRUD.R == true then the Jig was shared via team access settings.
    if (this.myTenantID === TenantHelpers.InvalidTenantID) {
      return false
    }

    const teamSharePermissions =
      this.jigMetadata.Tenants && this.jigMetadata.Tenants.find((t: TenantJigPermissions) => t.TenantID === this.myTenantID)

    return (
      teamSharePermissions != null &&
      teamSharePermissions.Permissions != null &&
      teamSharePermissions.Permissions.includes(JigConst.SharePermission.view)
    )
  }

  private get isFreeTier(): boolean {
    return this.myTenant.Subscription.TierType === SubscriptionConst.Subscription.subscriptionTierFree
  }

  private get isJigPasswordAlreadySet() {
    return !!this.jigMetadata.Password
  }

  private get sharedItem(): SharedJigItem {
    // When ShareableLink is imported in JigsListing page
    // Jig data returns as SharedJigItem type
    // When ShareableLink is imported in JigDetails page
    // Jig data returns as JigMetadata type
    // So we need to utilise the data into the same type.

    return {
      id: this.jigMetadata.Id,
      name: this.jigMetadata.ProjectName,
      author: this.jigMetadata.Author,
      thumbnail: this.jigMetadata.ThumbnailURL,
      deeplinkUrl: this.jigMetadata.DeeplinkURL || '',
      visibility: this.jigMetadata.ProjectVisibility,
      isOwner: this.isOwner,
      teamShared: this.isSharedWithTeam,
      isStaffOwned: this.isSuperUserOrJigStaff,
    }
  }

  private get eventAction(): string {
    if (this.$route.path.startsWith('/jig-detail')) {
      return 'JigDetail'
    } else if (this.$route.name === '/my-jigs') {
      if (this.$route.meta && this.$route.meta.teamJigView) {
        return 'TeamJigList'
      } else {
        return 'JigList'
      }
    }
    return ''
  }

  private isLinkPassword(): boolean {
    return this.activeVisibility === JigConst.JigVisibilities.Password
  }

  private activeConfigText(): string {
    return (this.configList[this.activeVisibility] as StandardObject).text
  }

  public ResetVisibility() {
    this.setJigVisibility(this.jigVisibility)
    this.$forceUpdate()
  }

  private setJigVisibility(val: JigConst.JigVisibilities) {
    if (val === JigConst.JigVisibilities.Public) {
      this.activeVisibility = JigConst.JigVisibilities.Link
    } else {
      this.activeVisibility = val
    }
  }

  private isVisibilityAvailableForTenant(visibility: string) {
    switch (visibility) {
      case JigConst.JigVisibilities.Private:
        return this.myTenant.Subscription.AllowPrivateJigs
      case JigConst.JigVisibilities.Password:
        return this.myTenant.Subscription.AllowPasswordJigs
      default:
        return true
    }
  }

  private shareableLinkSegmentTracking(payload: any) {
    const { event, activity } = payload
    const jig: SharedJigItem = this.sharedItem

    segmentEventTracking(event, {
      action: this.eventAction,
      jigId: jig.id,
      jigName: jig.name,
      jigOwner: `${jig.author}${jig.isStaffOwned ? ' (JigStaff)' : ''}`,
      jigThumbail: jig.thumbnail.indexOf('jig-placeholder-color') > -1 ? '' : jig.thumbnail,
      jigUrl: jig.deeplinkUrl,
      jigVisibility: jig.visibility,
      isJigOwner: jig.isOwner,
      teamShared: jig.teamShared,
      sharingActivity: activity,
    })
  }

  private triggerCopyShareableLinkPrompt(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    this.$emit('on-copy-shareable-link', {
      visibility: this.activeVisibility,
      data: {
        deeplink: v,
        showSuccessMessage,
        activity,
        event,
      },
    })
  }

  public async CopyShareableLink(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    await this.copyShareableLink(v, showSuccessMessage, activity, event)
  }

  private toggleSelectField(key: string) {
    if (!this.isJigEditable && key !== 'copy') {
      return
    }

    switch (key) {
      case 'visibility':
        this.isConfigSelectActive = !this.isConfigSelectActive
        break
      case 'copy':
        this.isCopyConfigSelectActive = !this.isCopyConfigSelectActive
        break
      default:
        break
    }
  }

  public ResetSelectField() {
    this.isConfigSelectActive = false
  }

  private updateShareConfig(config: any, visibility: JigConst.JigVisibilities) {
    if (!this.isJigEditable) {
      return
    }

    this.toggleSelectField('visibility')

    if (visibility === JigConst.JigVisibilities.Private && this.isFreeTier) {
      // Free tier cannot set to private share.
      // Show warning prompt and return.
      this.$emit('on-private-share-warning-triggered', {
        promptKey: 'privateWarning',
        eventName: 'SettingsPrivateSharing_FeatureGateShown',
      })
      return
    } else if (visibility === JigConst.JigVisibilities.Password && !this.myTenant.Subscription.AllowPasswordJigs) {
      // Only Org tier can set to password share.
      // Show warning prompt for other tiers and return.
      this.$emit('on-password-share-warning-triggered', {
        promptKey: 'passwordWarning',
        eventName: 'SettingsPasswordSharing_FeatureGateShown',
      })
      return
    } else if (
      visibility === JigConst.JigVisibilities.Password &&
      this.myTenant.Subscription.AllowPasswordJigs &&
      this.isJigPasswordAlreadySet
    ) {
      // Only Org tier can set to password share.
      // Show warning prompt for other tiers and return.
      this.$emit('on-password-regenerate-triggered', {
        promptKey: 'passwordRegenerate',
        visibility,
        callback: () => {
          this.$refs.hintTooltip.showHintTooltip()
        },
      })
      return
    }

    this.activeVisibility = visibility
    this.updateShareVisibility(visibility)
  }

  private updateShareVisibility(visibility: string) {
    this.$emit(JigConst.jigEvents.onUpdatingJigVisibility, {
      visibility,
      callback: () => {
        if (visibility === JigConst.JigVisibilities.Password) {
          this.$refs.hintTooltip.showHintTooltip()
        }
      },
    })
  }

  private updateCopyConfig(key: string) {
    if (key === JigConst.JigVisibilities.Password) {
      const copyContent = `You have been shared a private Jig, you'll need the password below:\n\n${this.sharedItem.name}\n${
        this.jigDeeplinkURL || this.jigMetadata.DeeplinkURL
      }\n\nPassword: ${this.jigMetadata.Password}`
      this.latestClipboardTitle = 'Link & password copied to clipboard!'

      this.triggerCopyShareableLinkPrompt(copyContent, true, 'JigSharedViaPasswordAndLink')
    } else {
      this.latestClipboardTitle = 'Link copied to clipboard!'
      this.triggerCopyShareableLinkPrompt(this.jigDeeplinkURL, true, 'JigSharedViaShareableLink')
    }

    this.toggleSelectField('copy')
  }

  private copyDeeplink(deeplink: string, event?: Event) {
    this.latestClipboardTitle = 'Link copied to clipboard!'
    this.triggerCopyShareableLinkPrompt(deeplink, true, 'JigSharedViaShareableLink', event)
  }

  private copyJigPassword() {
    this.latestClipboardTitle = 'Password copied to clipboard!'
    this.CopyShareableLink(this.jigMetadata.Password || '', true, 'JigSharedViaPasswordOnly')
  }
}
