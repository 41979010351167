
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class ShareSuccessSnackbarVue extends Vue {
  @Prop({ type: Boolean, default: false })
  public clipboardSnackbarVisible!: boolean
  @Prop({ type: String, default: '' })
  public latestClipboardEntry!: string
  @Prop({ type: Boolean, default: false })
  public isClipboardCopySuccess!: boolean
  @Prop({ type: String, default: 'Copied to clipboard' })
  public latestClipboardTitle!: string
  @Prop({ type: String, default: '' })
  public message!: string

  private snackTimeout: number = 5000
  private timeoutID: ReturnType<typeof setTimeout> | undefined

  @Watch('clipboardSnackbarVisible', { immediate: true })
  onClipboardSnackbarVisibleChanged(val: boolean) {
    if (val) {
      // When the snackbar auto closed make sure reset clipboardSnackbarVisible data.
      clearTimeout(this.timeoutID)

      this.timeoutID = setTimeout(() => {
        this.onSnackbarClose()
      }, this.snackTimeout)
    }
  }

  private get successMessage(): string {
    const copySuccessMessage = this.isClipboardCopySuccess ? `${this.latestClipboardTitle}:&nbsp;` : ': '

    return !!this.message
      ? this.message
      : `${copySuccessMessage}<a style="color: white" class="text-center" href="${this.latestClipboardEntry}" target="_blank"><u>${this.latestClipboardEntry}</u></a>`
  }

  private onSnackbarClose() {
    this.$emit('on-snackbar-close', false)
  }
}
