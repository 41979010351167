
import AuthImage from '@/components/image/AuthImage.vue'
import { AppConst } from '@/constants'
import { saveAs } from 'file-saver'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    'auth-image': AuthImage,
  },
})
export default class QrCodeTabVue extends Vue {
  @Prop({ type: String, default: '' })
  public jigId!: string

  public $refs!: Vue['$refs'] & {
    [key: string]: Vue | Element | Vue[] | Element[] | undefined
  }

  private isDownloading: boolean = false
  private isDownloadSuccessful: boolean = false

  private qrCodeSrcUrl(downloadLarge = false) {
    return `${AppConst.apiDomain}${AppConst.APIEndpoint.jigsV1Url}/${this.jigId}${
      downloadLarge ? AppConst.APIEndpoint.getLargeQRCodeUrl : AppConst.APIEndpoint.getSmallQRCodeUrl
    }`
  }

  private async downloadQRCode() {
    this.isDownloading = true

    if (this.$refs[`authImg${this.jigId}`]) {
      const downloadURL = await (this.$refs[`authImg${this.jigId}`] as AuthImage).GetSecureDownloadLink(this.qrCodeSrcUrl(true))

      await this.triggerDownload(downloadURL)

      this.$emit('on-qr-modal-downloaded')
    }
  }

  private async triggerDownload(qrCodeBlob: any) {
    await saveAs(qrCodeBlob, `qr-code-printable-${this.jigId}_8495×8495.png`)
    this.isDownloadSuccessful = true
    this.isDownloading = false
  }
}
