
import { Tenant } from '@/store/modules/app/types'
import { Namespace } from '@/store/types'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({})
export default class PasswordShareWarningVue extends Vue {
  @Prop({ required: true, type: String, default: '' })
  public eventName!: string
  @Prop({ required: true, type: String, default: '' })
  public callout!: string
  @Prop({ type: String, default: 'Upgrade' })
  public cta!: string

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant

  // Bind @click.native to <router-link> tag will not work, hence changing to manual router change.
  private onUpgradeClick() {
    segmentEventTracking(this.eventName, {
      tenantName: this.myTenant.Name,
      tenantId: this.myTenant.ID,
    })

    this.$router.push({ path: '/subscription' })
  }
}
