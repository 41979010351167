
/* eslint-disable-next-line lines-around-comment */
/**
 * The PermissionTabVue component is part of the Share Panel that manages permissions
 * relevant to the Jig. Users can perform the following actions:
 * 1. Manage 1:1 permissions for the Jig.
 * 2. Update the Jig's team share settings (options include 'view', 'sharable-readonly', 'edit').
 *
 * Computed Properties:
 * - detailed instruction availablel inside
 *
 * Mixin:
 * - JigPermissions (see details in /src/mixin/JigPermissions.ts)
 */

import HintTooltip from '@/components/helper/HintTooltip.vue'
import CalloutBanner from '@/components/share/CalloutBanner.vue'
import ShareUsers from '@/components/share/ShareUsers.vue'
import { JigConst, PermissionConst, SubscriptionConst } from '@/constants'
import Invites from '@/mixin/Invites'
import JigPermissions from '@/mixin/JigPermissions'
import { PermissionActions } from '@/store/constants'
import { BulkInviteRequest, CreateMultipleInvitePayload, InviteExt, InviteJigResource } from '@/store/modules/invites/types'
import { JigMetadata, Permission } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { mixins } from 'vue-class-component'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'share-users': ShareUsers,
    'callout-banner': CalloutBanner,
    'hint-tooltip': HintTooltip,
  },
})
export default class PermissionTabVue extends mixins(Invites, JigPermissions) {
  @Prop({ type: Array, default: () => [] })
  public jigIds!: string[]
  @Prop({ type: Number, default: -1 })
  public jigOwnerUid!: number
  @Prop({ type: Function, default: () => {} })
  public permissionUpdate!: Function
  @Prop({ type: Function, default: () => {} })
  public permissionDelete!: Function
  @Prop({ type: Boolean, default: true })
  @Prop({ type: Boolean, default: true })
  public isLoadingConfig!: boolean

  @State('jigDetailPermissions', { namespace: Namespace.Jig })
  public jigDetailPermissions!: Permission[]
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string

  @Action('createBulk', { namespace: Namespace.Invites })
  public inviteCreateBulk: any

  private isConfigSelectActive: any = {}
  private jigOwnerPermission: any = {}
  private userWithJigPermissions: any[] = []
  private tenantUsersWithNoJigPermissions: any[] = []
  private isAdvancedPermissionActive: boolean = false
  private currentEditor: string = ''
  private editorToInvite: string = ''
  private viewersToInvite: string[] = []
  private customInlineStyles: string = ''
  private isUserTooltip: boolean = false

  public $refs!: Vue['$refs'] & {
    hintTooltip: HintTooltip
    permission: HTMLElement
    advancedPermission: HTMLElement
  }

  private get calloutCopy(): string {
    let copy = ''

    if (this.teamCanEdit) {
      copy = 'Your whole team can edit this Jig'
    } else if (this.jigDetailPermissions.length > 1 && this.tenantUsers.length > 1 && !this.isMultiJigsShare) {
      copy = `This Jig is currently shared with ${this.jigDetailPermissions.length - 1} people`
    } else if (this.tenantUsers.length === 1) {
      copy = `Add team members to securely share ${!this.isMultiJigsShare ? 'this Jig' : `these ${this.jigIds.length} Jigs`} with them`
    } else if (this.isMultiJigsShare) {
      copy = `Share ${this.jigIds.length} Jigs with team members`
    } else {
      copy = "This Jig isn't currently shared with anyone"
    }

    return this.isLoadingConfig ? '' : copy
  }

  private get calloutCtaCopy(): string {
    let copy = ''

    if (this.tenantUsers.length > 1) {
      copy = this.isJigManagable ? 'Manage access' : 'Who can see this Jig?'
    } else {
      copy = 'Add team members'
    }

    return copy
  }

  // Jig can be edit when:
  // 1. Normal edit permission check (see JigPermissions.ts)
  // 2. there are multiple Jigs selected for sharing (so can assign view only permission)
  private get isJigEditable(): boolean {
    if (this.isMultiJigsShare) {
      return false
    }

    return this.canEditOrDeleteJig()
  }

  // Orders the tenantUsers array to display users with 1:1 permissions
  // first, followed by other users.
  private get orderedTenantUsers(): Array<any> {
    const allUsers = this.userWithJigPermissions.concat(this.tenantUsersWithNoJigPermissions)

    return allUsers
  }

  private get isMultiJigsShare(): boolean {
    return this.jigIds.length > 1
  }

  // Checks if the Jig is manageable based on specific conditions:
  // 1. There are multiple Jigs selected for sharing.
  // 2. The general managable check (see JigPermissions.ts)
  private get isJigManagable(): boolean {
    return this.isMultiJigsShare || this.canAssignIndividualPermission()
  }

  private get canViewUserList(): boolean {
    return this.isJigOwner() || this.myTenant.ID === this.jigMetadata.OwnerTenantID || this.isTeamManagerOfJigOwnerTenant()
  }

  private get isOrgTier() {
    return this.myTenant.Subscription && this.myTenant.Subscription.TierType === SubscriptionConst.Subscription.subscriptionTierOrganization
  }

  private get isFreeTier() {
    return this.myTenant.Subscription && this.myTenant.Subscription.TierType === SubscriptionConst.Subscription.subscriptionTierFree
  }

  private get tabWidth(): number {
    return this.$refs.permission.clientWidth
  }

  private get permissionHintTooltipCopy(): string {
    return this.jigIds.length === 1 ? JigConst.jigPermissionViewerErrors.single : JigConst.jigPermissionViewerErrors.multiple
  }

  protected mounted() {
    // When included as dynamic component inside a deeper level child component,
    // i.e. child-component inside child-component,
    // $refs.permissionTab will be unavailable on modal activation.
    // In this case we need to let parent know when it's mounted/updated where $refs are updated.
    this.$emit(this.jigShareEvents.onPermissionTabMounted)
  }

  protected updated() {
    this.$emit(this.jigShareEvents.onPermissionTabUpdated)
  }

  public ResetTeamSharePermissions(permissions?: JigConst.SharePermission[]) {
    if (permissions != null) {
      this.updateTeamSharePermissions(permissions)
      this.teamSharePermModel = this.teamSharePermissions
      this.teamSharePermModelOldVal = this.teamSharePermissions
    } else {
      this.loadJigTeamPermissions()
    }
  }

  public LoadJigDetails(data: any) {
    const { myTenantID, resetPermissionTab, skipIndividualPermissionCheck } = data
    if (myTenantID) {
      this.myTenantID = myTenantID
    }
    this.prepareUserList(resetPermissionTab, skipIndividualPermissionCheck)

    if (!this.isMultiJigsShare) {
      this.updateMyTenantIndex()
      this.loadJigTeamPermissions()
    }
  }

  public loadJigTeamPermissions() {
    this.updateTeamSharePermissions(
      this.jigMetadata.Tenants && this.jigMetadata.Tenants[this.myTenantIndex] && this.jigMetadata.Tenants[this.myTenantIndex].Permissions
        ? this.jigMetadata.Tenants[this.myTenantIndex].Permissions
        : []
    )

    this.teamSharePermModel = this.teamSharePermissions
    this.teamSharePermModelOldVal = this.teamSharePermissions
  }

  private resetValues(resetPermissionTab = true) {
    this.currentEditor = ''
    this.jigOwnerPermission = {}
    this.userWithJigPermissions = []
    this.tenantUsersWithNoJigPermissions = []
    this.editorToInvite = ''
    this.viewersToInvite = []
    this.failedInvites = []

    if (resetPermissionTab) {
      this.isAdvancedPermissionActive = false
      this.isConfigSelectActive = {}
    }
  }

  private prepareUserList(resetPermissionTab = true, skipIndividualPermissionCheck = false) {
    this.resetValues(resetPermissionTab)
    this.jigOwnerPermission = ListHelpers.getItemById(this.jigDetailPermissions, 'AudID', this.jigOwnerUid)

    this.tenantUsers.forEach((user: any, index: number) => {
      const matchingUser = this.jigDetailPermissions.find((p: any) => p.AudName === user.email)

      delete user.CRUD
      delete user.jigPermissions
      user.isUpdatingPermission = false
      this.isConfigSelectActive[user.ID] = false

      if (!matchingUser) {
        // User with no permission to view/edit this Jig
        this.tenantUsersWithNoJigPermissions.push(user)
      } else {
        if (matchingUser.CRUD.U) {
          this.currentEditor = matchingUser.AudName
        }

        if (!skipIndividualPermissionCheck) {
          user.CRUD = matchingUser.CRUD
          user.jigPermissions = {
            ID: matchingUser.ID,
            ResID: matchingUser.ResID,
          }
        }

        if (user.Uid !== this.jigOwnerPermission.AudID && !skipIndividualPermissionCheck) {
          // None-owner user with view or edit permission of this Jig
          this.userWithJigPermissions.push(user)
        } else if (user.Uid === this.jigOwnerPermission.AudID && !skipIndividualPermissionCheck) {
          // Owner user with view or edit permission of this Jig
          this.userWithJigPermissions.unshift(user)
        } else if (user.Uid !== this.jigOwnerPermission.AudID) {
          this.tenantUsersWithNoJigPermissions.push(user)
        }
      }
    })

    // Append the users with Permissions that not belong to current Tenant
    if (!skipIndividualPermissionCheck && this.jigDetailPermissions.length > this.userWithJigPermissions.length) {
      this.jigDetailPermissions.forEach((p: any) => {
        if (!this.userWithJigPermissions.find((u: any) => u.email === p.AudName)) {
          const nonActiveTenantUser = {
            Uid: p.AudID,
            email: p.AudName,
            username: p.AudName,
            CRUD: p.CRUD,
            isUpdatingPermission: false,
            jigPermissions: {
              ID: p.ID,
              ResID: p.ResID,
            },
            roles: [] as Array<string>,
          }

          // Unity app use following code to check if user is Guest viewer.
          // public bool HasExpired()
          // {
          //     return Valid && DateTime.Compare(DateTime.Parse(Time), DateTime.Now) < 0
          // }
          if (p.ExpiresAt.Valid && new Date(p.ExpiresAt.Time).valueOf() < new Date().valueOf()) {
            nonActiveTenantUser.roles.push(TenantHelpers.roleGuestViewer1)
          } else {
            nonActiveTenantUser.roles.push(TenantHelpers.statusDeletedUser)
          }

          this.userWithJigPermissions.push(nonActiveTenantUser)
        }
      })
    }
  }

  public TriggerSharePanel() {
    this.isAdvancedPermissionActive = true
    if (this.isFreeTier) {
      segmentEventTracking('SharingAccessPanel_FeatureGateShown', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenant.ID,
        jigName: this.jigMetadata.ProjectName,
        jigId: this.jigMetadata.Id,
      })
    }
    this.$emit(this.jigShareEvents.onAdvancedPermissionTriggered)
  }

  public CloseSharePanel() {
    this.isAdvancedPermissionActive = false
    this.isConfigSelectActive = {}
  }

  private async deleteUserPermission(user: any) {
    const req = {
      PermID: user.jigPermissions.ID,
      ResID: user.jigPermissions.ResID,
    }
    this.$emit(this.jigEvents.onUpdateJigShareConfig, { permissionsDirty: true })
    await this.permissionDelete(req)

    if (this.apiError !== '') {
      this.$emit(this.jigPermissionEvents.onPermissionUpdateError, {
        isLoadingConfig: false,
      })
    }

    this.$emit(this.jigPermissionEvents.onPermissionUpdateCallback, () => {
      this.LoadJigDetails({ resetPermissionTab: false })

      this.$emit(this.jigPermissionEvents.onDeletePermissionSuccessful, {
        isLoadingConfig: false,
      })
    })
  }

  private async updateOneToOnePermission(user: any, canEdit = false) {
    const pv = this.jigDetailPermissions.find((p: any) => p.AudName === user.email)

    if (pv) {
      pv.CRUD.R = true
      pv.CRUD.U = canEdit

      const req = {
        Perm: pv,
        JigId: this.jigMetadata.Id,
      }

      this.$emit(this.jigEvents.onUpdateJigShareConfig, { permissionsDirty: true })
      await this.permissionUpdate(req)

      if (this.apiError !== '') {
        this.$emit(this.jigPermissionEvents.onPermissionUpdateError, {
          isLoadingConfig: false,
        })
      }

      this.$emit(this.jigPermissionEvents.onPermissionUpdateCallback, () => {
        this.LoadJigDetails({ resetPermissionTab: false })

        const successfulPayload: any = {
          isLoadingConfig: false,
        }

        if (!this.isMultiJigsShare) {
          successfulPayload.jigId = this.jigMetadata.Id
        } else {
          // successfulPayload.hideAdvancedPermissionTab = true
        }

        this.$emit(this.jigPermissionEvents.onEditorUpdateSuccessful, successfulPayload)
      })
    }
  }

  private async inviteViewersOrEditors() {
    const createBulkInviteReq: CreateMultipleInvitePayload = {
      requests: [],
    }
    let editorEmail = ''

    editorEmail = this.currentEditor

    if (this.editorToInvite) {
      editorEmail = this.editorToInvite
      const resourcesToEdit = []
      for (const jid of this.jigIds) {
        resourcesToEdit.push({
          resourceHashID: jid,
          resourceType: PermissionConst.PermissionsResourceType.Jig,
          permActions: [PermissionActions.View, PermissionActions.Edit],
        })
      }
      const editorInvite = {
        inviteeEmail: this.editorToInvite,
        inviterUserID: this.$auth0.uid,
        tenantID: TenantHelpers.InvalidTenantID, // Set by the action
        resources: resourcesToEdit,
        app: 'jigspace',
      }
      const inviteCreateRequest: BulkInviteRequest = {
        invitation: editorInvite,
      }
      createBulkInviteReq.requests.push(inviteCreateRequest)
    }

    // Create request payloads for viewer invites
    if (this.viewersToInvite.length > 0) {
      const resources: InviteJigResource[] = []
      for (const jid of this.jigIds) {
        resources.push({
          resourceHashID: jid,
          resourceType: PermissionConst.PermissionsResourceType.Jig,
          permActions: [PermissionActions.View],
        })
      }
      for (const email of this.viewersToInvite) {
        // We don't need to send the editor an additional invite...
        if (editorEmail === email) {
          continue
        }
        const inv = {
          inviteeEmail: email,
          inviterUserID: this.$auth0.uid,
          tenantID: TenantHelpers.InvalidTenantID, // Set by the action
          resources,
          app: 'jigspace',
        }
        const inviteCreateRequest = {
          invitation: inv,
        }
        createBulkInviteReq.requests.push(inviteCreateRequest)
      }
    }

    this.$emit(this.jigEvents.onUpdateJigShareConfig, { permissionsDirty: true })
    await this.inviteCreateBulk(createBulkInviteReq)

    if (this.apiError !== '') {
      this.$emit(this.jigPermissionEvents.onPermissionUpdateError, {
        isLoadingConfig: false,
      })
    }

    this.extractFailedInvites()

    if (this.failedInvites.length > 0) {
      this.$emit(this.jigPermissionEvents.onInviteFailed, {
        promptKey: 'failedInvites',
        failedInvitesCopy: this.craftFailedInvitesCopy(),
      })
    } else if (!this.isMultiJigsShare) {
      // The invite approach doesn't reload permissions, so we must do that here now
      this.$emit(this.jigPermissionEvents.onPermissionUpdateCallback, () => {
        this.LoadJigDetails({ resetPermissionTab: false })

        this.$emit(this.jigPermissionEvents.onInviteSuccessful, {
          editorEmail,
          viewersEmails: this.viewersToInvite,
          isLoadingConfig: false,
        })
      })
    } else {
      // We sent bulk invites with no failures, disable loading status
      this.$emit(this.jigPermissionEvents.onBulkInviteSuccessful, {
        isLoadingConfig: false,
        hideAdvancedPermissionTab: true,
        closeModal: true,
      })
    }
  }

  private async onUpdateUserPermission(payload: any) {
    const { user, permission } = payload

    this.$emit(this.jigPermissionEvents.onPermissionUpdateStart, {
      isLoadingConfig: true,
    })

    switch (permission) {
      case 'delete':
        await this.deleteUserPermission(user)
        return
      case 'edit':
        if (this.isMultiJigsShare) {
          this.editorToInvite = user.email
          await this.inviteViewersOrEditors()
        } else {
          await this.updateOneToOnePermission(user, true)
        }
        return
      case 'initial-view':
        this.editorToInvite = ''
        // first time assign view permission,
        // we will need to send invite email.
        this.viewersToInvite.push(user.email)
        await this.inviteViewersOrEditors()
        return
      default:
        // view permission
        this.editorToInvite = ''
        if (this.isMultiJigsShare) {
          this.viewersToInvite.push(user.email)
          await this.inviteViewersOrEditors()
        } else {
          await this.updateOneToOnePermission(user)
        }
        return
    }
  }

  private ctaTrackingEvent() {
    segmentEventTracking('SharingAccessPanel_UpgradePanelClicked', {
      tenantName: this.myTenant.Name,
      tenantId: this.myTenant.ID,
      jigName: this.jigMetadata.ProjectName,
      jigId: this.jigMetadata.Id,
    })
  }

  private toggleViewerHintToolTip(event: MouseEvent) {
    if (this.isJigEditable || this.isJigManagable) {
      return
    }

    this.$refs.hintTooltip.showHintTooltip()
  }

  private handleMouseEnter(event: MouseEvent) {
    if (this.isJigEditable || this.isJigManagable) {
      return
    }

    this.isUserTooltip = false
    this.updateTooltipPosition(event.offsetX)

    if (!this.$refs.hintTooltip.isHintTooltipActive) {
      this.$refs.hintTooltip.showHintTooltip()
    }
  }

  private handleMouseLeave() {
    if (this.isJigEditable || this.isJigManagable) {
      return
    }

    this.$refs.hintTooltip.hideHintTooltip()
  }

  private handleMouseEnterSelect(event: MouseEvent) {
    if (this.isJigEditable || this.isJigManagable) {
      return
    }
    this.isUserTooltip = true
    const selectedField = event.target as HTMLElement
    const userRow = selectedField.parentNode as HTMLElement
    const selectFieldHeight = selectedField.clientHeight
    const userRowOffsetTop = userRow.offsetTop
    const userListScrollDistance = this.$refs.advancedPermission.scrollTop
    let tooltipTopPos = 0

    if (window.innerWidth > 768) {
      tooltipTopPos = userRowOffsetTop - userListScrollDistance - userRow.clientHeight / 2
    } else {
      tooltipTopPos = userRowOffsetTop - userListScrollDistance - selectFieldHeight

      if (tooltipTopPos < 0) {
        tooltipTopPos = 0
      }
    }

    // minus margint top 16px
    this.customInlineStyles = `top: ${tooltipTopPos - 16}px;`

    if (!this.$refs.hintTooltip.isHintTooltipActive) {
      this.$refs.hintTooltip.showHintTooltip()
    }
  }

  private updateTooltipPosition(newOffsetX: number) {
    const newLeftPos = newOffsetX - this.$refs.hintTooltip.$el.clientWidth / 2
    const newRightPos = newOffsetX + this.$refs.hintTooltip.$el.clientWidth / 2

    if (newRightPos > this.tabWidth) {
      this.customInlineStyles = 'left: auto; right: 0;'
    } else if (newLeftPos < 0) {
      this.customInlineStyles = 'left: 0; right: auto;'
    } else {
      this.customInlineStyles = `left: ${newLeftPos}px; right: auto;`
    }
  }
}
