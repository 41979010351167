
import UserAvatar from '@/components/image/UserAvatar.vue'
import CalloutBanner from '@/components/share/CalloutBanner.vue'
import { JigConst, SubscriptionConst } from '@/constants'
import { Tenant } from '@/store/modules/app/types'
import { JigMetadata } from '@/store/modules/jig/types'
import { Namespace, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
declare var whiteLog: any

@Component({
  components: {
    'callout-banner': CalloutBanner,
    'user-avatar': UserAvatar,
  },
})
export default class ShareUsersVue extends Vue {
  @Prop({ type: Array, default: () => [] })
  public orderedTenantUsers!: any[]
  @Prop({ type: Object, default: () => ({}) })
  public jigOwnerPermission!: StandardObject
  @Prop({ type: Object, default: () => ({}) })
  public isConfigSelectActiveValue!: StandardObject
  @Prop({ type: Boolean, default: false })
  public isJigManagable!: boolean
  @Prop({ type: Boolean, default: false })
  public isMultiJigsShare!: boolean
  @Prop({ type: String, default: '' })
  public currentEditor!: string

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetadata

  private isConfigSelectActive: StandardObject = {}
  private configList: StandardObject = {
    view: {
      icon: 'Icon_View.svg',
      text: 'Can view',
    },
    edit: {
      icon: 'Icon_Edit.svg',
      text: 'Can edit',
    },
    delete: {
      icon: 'Icon_Bin.svg',
      text: 'Remove access',
    },
  }
  private permissionToCURDMap: StandardObject = {
    U: 'edit',
    R: 'view',
  }
  private rolesToDisplay: string[] = [TenantHelpers.ExtraPermissionManager]

  @Watch('isConfigSelectActiveValue')
  private onIsConfigSelectActiveValueChanged(val: StandardObject) {
    this.isConfigSelectActive = { ...val }
  }

  private get individualPermissionHintCopy(): string {
    return JigConst.jigPermissionViewerErrors.single
  }

  private get isOrgTier(): boolean {
    return this.myTenant.Subscription && this.myTenant.Subscription.TierType === SubscriptionConst.Subscription.subscriptionTierOrganization
  }

  private get maxUserSeats(): number {
    const seatsIncluded: number =
      this.myTenant.Subscription && typeof this.myTenant.Subscription.SeatsIncludedInTier === 'number'
        ? this.myTenant.Subscription.SeatsIncludedInTier
        : 0
    const additionalSeats: number =
      this.myTenant.Subscription && this.myTenant.Subscription.AdditionalSeats ? this.myTenant.Subscription.AdditionalSeats : 0

    return seatsIncluded + additionalSeats
  }

  private disableAction(user: any) {
    return !this.isJigManagable || this.isOwnerEditor(user) || this.$auth0.uid === user.Uid
  }

  private userDisplayName(user: any) {
    let appendix = ''
    if (user.roles.includes(TenantHelpers.statusDeletedUser)) {
      appendix = ' (Removed from team)'
    } else if (user.roles.includes(TenantHelpers.roleGuestViewer1)) {
      appendix = ' (Guest)'
    } else if (user.roles.includes(TenantHelpers.rolePresenter1)) {
      appendix = ' (Presenter)'
    } else {
      whiteLog(`User ${user.name || user.email} role display skipped`, user.roles)
    }
    return `${user.name || user.email}<span class="user__appendix">${appendix}</span>`
  }

  private isOwner(uid: number) {
    return this.jigMetadata.Uid === uid
  }

  private isOwnerEditor(user: any) {
    return this.isOwner(user.Uid) && user.CRUD && user.CRUD.U
  }

  private userRoleDisplay(user: any) {
    const validRoles: string[] = user.tenantAdminRoles
      ? user.tenantAdminRoles.filter((perm: string) => this.rolesToDisplay.includes(perm))
      : []

    if (this.jigMetadata.Uid === user.Uid) {
      validRoles.unshift('Owner')
    }

    return validRoles.join(', ')
  }

  private userConfigList(user: any) {
    const userConfigOptions: any = { ...this.configList }

    if (user.email === this.jigOwnerPermission.AudName || this.isMultiJigsShare) {
      delete userConfigOptions.delete
    }

    // Both guest user and presenter have no access to dashboard at all, they should not be able to have Jig edit permission.

    if (
      (this.currentEditor === user.email && !this.isMultiJigsShare) ||
      user.permissions == null ||
      user.permissions.jigs == null ||
      user.permissions.jigs.edit == null ||
      user.roles.includes(TenantHelpers.statusDeletedUser)
    ) {
      delete userConfigOptions.edit
    }

    // Jig Owner retains edit permission
    if (this.isOwner(user.Uid) || user.roles.includes(TenantHelpers.statusDeletedUser)) {
      delete userConfigOptions.view
    }

    return userConfigOptions
  }

  private toggleSelectField(user: any) {
    if (this.disableAction(user)) {
      return
    }

    this.isConfigSelectActive = {
      [user.Uid]: !this.isConfigSelectActive[user.Uid],
    }
  }

  private userJigPermission(user: any) {
    if (user.CRUD && user.CRUD.U) {
      // Can edit
      return this.configList[this.permissionToCURDMap.U].text
    } else if (user.CRUD && user.CRUD.R) {
      // Can view
      return this.configList[this.permissionToCURDMap.R].text
    } else {
      return 'Give access'
    }
  }

  private onUpdateUserPermission(user: any, permission: string | any, toggleSelect: boolean = true) {
    if (!this.isJigManagable) {
      return
    }

    if (toggleSelect) {
      this.toggleSelectField(user)
    }

    this.$emit('on-update-user-permission', { user, permission })
  }

  private learnMoreTracking() {
    segmentEventTracking('SharingAccessPanel_FeatureGateLearnMore', {
      tenantName: this.myTenant.Name,
      tenantId: this.myTenant.ID,
      jigName: this.jigMetadata.ProjectName,
      jigId: this.jigMetadata.Id,
    })
  }

  private handleMouseEnter(event: MouseEvent, targetClass: string = '') {
    if (targetClass !== '' && !(event.target as HTMLElement).classList.contains(targetClass)) {
      return
    }

    this.$emit('on-mouse-enter-select', event)
  }

  private handleMouseLeave() {
    this.$emit('on-mouse-leave-select')
  }
}
